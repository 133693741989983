<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">구매요청품목</strong>
      </div>
      <!-- <div class="pos_right">

      </div> -->
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm12">
      <table>
        <caption class="ir_caption">
          상세품명 목록 표
        </caption>
        <colgroup>
          <col style="width: 62px" />
          <!-- No -->
          <col />
          <!-- 품명 -->
          <col style="width: 138px" />
          <!-- 품목코드 -->
          <col style="width: 132px" />
          <!-- 입고처 -->
          <col style="width: 92px" />
          <!-- 수량 -->
          <col style="width: 92px" />
          <!-- 단가 -->
          <col style="width: 92px" />
          <!-- 금액 -->
          <col style="width: 144px" />
          <!-- 프로젝트코드 -->
          <col style="width: 106px" />
          <!-- 품목상세 -->
          <col style="width: 86px" />
          <!-- 추가/삭제 -->
        </colgroup>
        <thead>
          <th scope="col">No.</th>
          <th scope="col">품명</th>
          <th scope="col">품목코드</th>
          <th scope="col">입고처</th>
          <th scope="col">수량</th>
          <th scope="col">단가</th>
          <th scope="col">금액</th>
          <th scope="col">프로젝트코드</th>
          <th scope="col">품목상세</th>
          <th scope="col">
            <button class="btn_small btn_secondary" @click="onClickAddGood">추가</button>
          </th>
        </thead>
        <tbody>
          <DraftPurchaseGoodsListLine
            v-for="(item, index) in model.purchaseGoodsList"
            :key="index"
            :index="index"
            :item="item"
            :isDisabledRemove="model.purchaseGoodsList.length === 1"
            @onClickSearchGoodsName="$emit('onClickSearchGoodsName', index)"
            @onClickSearchProjectCode="$emit('onClickSearchProjectCode', 'goods', index)"
            @onClickRemove="onClickRemoveGood(item)"
            @updatePrice="updatePrice"
          />
        </tbody>
      </table>
    </div>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>합계</dt>
          <dd>{{ priceTotal | currency }}</dd>
          <!-- <dd>20,000,000<span class="util_total">KRW</span></dd> -->
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DraftPurchaseGoodsListLine from "./DraftPurchaseGoodsListLine";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default {
  name: "DraftPurchaseGoodsList",
  components: {
    DraftPurchaseGoodsListLine,
  },
  props: {
    // DraftPurchaseGoodsListModel: Array,
    model: Object,
  },
  data() {
    return {
      currentList: [
        { code: "EA", name: "EA" },
        { code: "식", name: "식" },
        { code: "BOX", name: "Box" },
        { code: "직접입력", name: "직접입력" },
      ],
      currentSelected: { code: "EA", name: "EA" },
    };
  },
  computed: {
    priceTotal() {
      return this.model.totalGoodsPrice();
      // const { purchaseGoodsList } = this.model;

      // const reducer = (accumulator, item) => {
      //   const price = getMoneyToNumber(item.price);

      //   return accumulator + price;
      // };

      // return this.model.reduce(reducer, 0);
    },
    isDirectMode() {
      return this.currentSelected.name == "직접입력";
    },
  },
  methods: {
    onClickAddGood() {
      this.model.addGood();
    },
    onClickRemoveGood(itemToRemove) {
      this.model.removeGood(itemToRemove);
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    selectCurrent(selectedCurrentObj) {
      this.currentSelected = selectedCurrentObj;
    },
    updatePrice(index) {
      this.model.updatePrice(index);
    },
  },
};
</script>
